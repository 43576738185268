import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ImageService } from 'src/app/services/image/image.service';
import { ProjectService } from 'src/app/services/project/project.service';
import { IProject } from 'src/app/types/IProject';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {

  headerTitle: string;

  project: IProject;
  shortStartedDate: string;
  shortFinishedDate?: string;

  decorations: string[];
  leftDecoration: string;
  rightDecoration: string;



  //Demander à Neno comment mieux récupérer les leservices
  constructor(private route: ActivatedRoute, private projectService: ProjectService, private imageService: ImageService) {
    //Récupération du projet
    this.project = this.projectService.getProject(this.route.snapshot.params['projectid']);
    this.headerTitle = "Projet " + this.project.name;

    //Récupération de la date de création du projet au bon format
    this.shortStartedDate = this.getShortDate(this.project.startedAt)!;
    this.shortFinishedDate = this.getShortDate(this.project.finishedAt);

    //Récupération des images de décoration du carousel
    this.decorations = this.imageService.listDecorations();
    this.leftDecoration = this.getRandomDecoration(this.decorations);
    this.rightDecoration = this.getRandomDecoration(this.decorations);
  }

  ngOnInit(): void { }

  getRandomDecoration(decorations: string[]) {
    /** Fonction retournant aléatoirement le nom d'une des images de la liste decorationsList. */

    const decorationName: string = this.imageService.getRandomDecoration(decorations);

    return decorationName;
  }

  getShortDate(date: Date | undefined): string | undefined {
    /** Fonction retournant la date de création du projet sous forme de string (mois puis année). */
    const monthList: string[] = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août",
      "Septembre", "Octobre", "Novembre", "Décembre"];
    let shortDate: string | undefined;

    if (date) {
      shortDate = monthList[date.getMonth()] + " " + date.getFullYear().toString();
    }

    return shortDate;
  }

  handleImageErrorProject(event: Event, placeholder: string): void {
    /** Fonction remplaçant l'image de l'élément lié à event par l'image placeholder. */
    this.imageService.handleImageError(event, placeholder);
  }

  captionMouseEnter(event: Event) {
    (event.target as HTMLImageElement).setAttribute("style", "opacity: 0;");
  }

  captionMouseLeave(event: Event) {
    (event.target as HTMLImageElement).setAttribute("style", "opacity: 1;");
  }

}
