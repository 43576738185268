<!-- <p>projects works!</p> -->

<app-header [title]="'Mes projets'"></app-header>

<div class="main-wrapper">
    <p>Tri des projets :</p>

    <div class="sorting-params">
        <select #keySelect (change)="changeSortingKey(keySelect.value)">
            <option *ngFor="let key of sortingKeys" value="{{key[1]}}">{{key[0]}}</option>
        </select>
        <select #orderSelect (change)="changeSortingOrder(orderSelect.value)">
            <option *ngFor="let order of sortingOrders" value="{{order[1]}}">{{order[0]}}
            </option>
        </select>
    </div>

    <div class="projects">
        <div *ngFor="let project of projects">
            <app-project-card [project]="project"></app-project-card>
        </div>
    </div>
</div>