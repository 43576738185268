// Fichier exportant les différentes données des technologies disponibles (à mettre à jour au fur et à mesure).

import { ITechnology } from "src/app/types/ITechnology";

export const c: ITechnology = {
    name: "C",
    fileName: "c.svg"
}

export const cpp: ITechnology = {
    name: "C++",
    fileName: "cpp.svg"
}

export const cs: ITechnology = {
    name: "C#",
    fileName: "cs.svg"
}

export const go: ITechnology = {
    name: "Go",
    fileName: "go.svg"
}

export const java: ITechnology = {
    name: "Java",
    fileName: "java.svg"
}

export const js: ITechnology = {
    name: "JavaScript",
    fileName: "js.svg"
}

export const kotlin: ITechnology = {
    name: "Kotlin",
    fileName: "kotlin.svg"
}

export const php: ITechnology = {
    name: "PHP",
    fileName: "php.svg"
}

export const python: ITechnology = {
    name: "Python",
    fileName: "python.svg"
}

export const ruby: ITechnology = {
    name: "Ruby",
    fileName: "ruby.svg"
}

export const rust: ITechnology = {
    name: "Rust",
    fileName: "rust.svg"
}

export const swift: ITechnology = {
    name: "Swift",
    fileName: "swift.svg"
}

export const bash: ITechnology = {
    name: "Bash",
    fileName: "bash.svg"
}

export const laravel: ITechnology = {
    name: "Laravel",
    fileName: "laravel.svg"
}

export const html: ITechnology = {
    name: "HTML",
    fileName: "html.svg"
}

export const css: ITechnology = {
    name: "CSS",
    fileName: "css.svg"
}

export const symfony: ITechnology = {
    name: "Symfony",
    fileName: "symfony.svg"
}

export const godot: ITechnology = {
    name: "Godot",
    fileName: "godot.svg"
}

export const react: ITechnology = {
    name: "React",
    fileName: "react.svg"
}

export const discordJs: ITechnology = {
    name: "DiscordJs",
    //Revoir fichier
    fileName: "discordjs.svg"
}