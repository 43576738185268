// Fichier exportant différentes données relatives au router de l'application (liens ILink, etc).

import { IProject } from "src/app/types/IProject";
import { IImage } from "src/app/types/IImage";
import { ITechnology } from "src/app/types/ITechnology";
import * as technology_data from "./technology_data";

export const hyruleCastle: IProject = {
    id: 0,
    name: "Hyrule Castle",
    description: "Jeu en lignes de commandes de combats en tour par tour basé sur l'univers de Zelda." +
        " Le joueur doit affronter des monstres puis un boss et peut choisir d’attaquer ou de se soigner à chaque tour.",
    images: [{ fileName: "hyrule_castle1.png", caption: "Exemple de déroulement d'une partie." }],
    technologies: [technology_data.bash],
    githubLink: "https://github.com/Majonnie/hyrule-castle",
    isWip: false,
    startedAt: new Date("2021-09-24"),
    finishedAt: new Date("2021-09-25"),
    //REVOIR DATES
    createdAt: new Date(),
    updatedAt: new Date()
}

export const clickerGame: IProject = {
    id: 1,
    name: "Clicker Game",
    description: "Jeu consistant à obtenir un maximum de ressources en cliquant" +
        " répétitivement sur un bouton et en achetant des améliorations.",
    images: [{ fileName: "clicker_game1.png", caption: "Exemple de partie avec toutes les améliorations débloquées." }],
    technologies: [technology_data.html, technology_data.css, technology_data.js],
    githubLink: "https://github.com/Majonnie/MyClickerGame",
    isWip: false,
    startedAt: new Date("2021-11-24"),
    finishedAt: new Date("2021-11-28"),
    //REVOIR DATES
    createdAt: new Date(),
    updatedAt: new Date()

}

export const libertyCommerce: IProject = {
    id: 2,
    name: "Liberty Commerce",
    description: "Création d'un site d'e-commerce à l'aide du framework Laravel : catalogue trié par pays," +
        " gestion des produits pour les administrateurs, panier, profil de l'utilisateur avec historique des commandes...",
    images: [{ fileName: "liberty_commerce1.png", caption: "Page d'accueil du site." }],
    technologies: [technology_data.laravel, technology_data.html, technology_data.css],
    githubLink: "https://github.com/Majonnie/LibertyCommerce",
    isWip: false,
    startedAt: new Date("2021-10-25"),
    finishedAt: new Date("2021-11-21"),
    //REVOIR DATES
    createdAt: new Date(),
    updatedAt: new Date()
}

export const j2b: IProject = {
    id: 3,
    name: "Réalisation d’un site pour J2B",
    description: "Création d'un outil de génération de factures afin de répondre à un besoin d'organisation de l'entreprise.",
    images: [{ fileName: "site_J2B1.png", caption: "Page d'accueil du site." }],
    technologies: [technology_data.symfony, technology_data.html, technology_data.css],
    githubLink: "https://github.com/garoNits/J2BCamp",
    isWip: false,
    startedAt: new Date("2021-12-06"),
    finishedAt: new Date("2021-12-16"),
    //REVOIR DATES
    createdAt: new Date(),
    updatedAt: new Date()
}

export const myLs: IProject = {
    id: 4,
    name: "my_ls",
    description: "Réimplémentation de la commande ls en C.",
    images: [{ fileName: "my_ls1.png", caption: "Exemple d'exécution du programme avec les options -a et -l." }],
    technologies: [technology_data.c],
    githubLink: "https://github.com/Majonnie/my_ls",
    isWip: false,
    startedAt: new Date("2022-01-03"),
    finishedAt: new Date("2022-01-16"),
    //REVOIR DATES
    createdAt: new Date(),
    updatedAt: new Date()
}

export const defiGraven: IProject = {
    id: 5,
    name: "Défi Graven (Dream Travel)",
    description: "Création d'un jeu-vidéo rogue-like (en travaillant principalement sur la construction de maps avec les tilemaps," +
        " le design et les animations du personnage et des ennemis, la caméra et les mouvements).",
    images: [{ fileName: "defi_graven1.png", caption: "Menu principal du jeu Dream Travel." }],
    technologies: [technology_data.godot],
    githubLink: "https://github.com/Majonnie/CodeCampsGraven/tree/fusion-back",
    isWip: false,
    startedAt: new Date("2022-03-07"),
    finishedAt: new Date("2022-03-14"),
    //REVOIR DATES
    createdAt: new Date(),
    updatedAt: new Date()
}

//Nouveaux projets

export const theShipProject: IProject = {
    id: 6,
    name: "The Ship Project",
    description: "Création d'une application web de mise à disposition d'idées de projets informatiques pour les développeurs" +
        " souhaitant accumuler des compétences techniques rapidement par la pratique. - Projet de fin d'Année Préparatoire",
    images: [{ fileName: "the_ship_project1.png", caption: "Page d'accueil de l'application." },
    { fileName: "the_ship_project2.png", caption: "Page d'accueil de l'application (bas)." }],
    technologies: [technology_data.react],
    isWip: false,
    //REVOIR DATES
    startedAt: new Date("2022-01-09"),
    finishedAt: new Date("2023-01-20"),
    createdAt: new Date(),
    updatedAt: new Date()
}

export const majoDiscordBot: IProject = {
    id: 7,
    name: "Majo's Discord Bot",
    description: "Mon premier projet de bot Discord, utilisant la librairie DiscordJs. Il contient actuellement des slash" +
        " commands, et analyse le flux de messages envoyés dans les serveurs où il se trouve. Il peut pour le moment envoyer" +
        " des messages simples, dans le serveur ou en messages privés.",
    //Revoir images
    images: [],
    technologies: [technology_data.discordJs],
    githubLink: "https://github.com/Majonnie/majo-bot-discord",
    isWip: true,
    startedAt: new Date("2023-02-16"), //
    //REVOIR DATES
    createdAt: new Date(),
    updatedAt: new Date()
}

export let projects: IProject[] = [hyruleCastle, clickerGame, libertyCommerce, j2b, myLs, defiGraven, theShipProject, majoDiscordBot];