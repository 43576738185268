import { Component, OnInit } from '@angular/core';
import { ImageService } from 'src/app/services/image/image.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  decorations: string[];
  leftDecoration: string;
  rightDecoration: string;

  constructor(private imageService: ImageService) {

    //Récupération des images de décoration du carousel
    this.decorations = this.imageService.listDecorations();
    this.leftDecoration = this.getDecoration(this.decorations);
    this.rightDecoration = this.getDecoration(this.decorations);
  }

  ngOnInit(): void {
  }

  getDecoration(decorations: string[]) {
    /** Fonction retournant aléatoirement le nom d'une des images de la liste decorations. */

    const decorationName: string = this.imageService.getRandomDecoration(decorations);

    return decorationName;
  }



}
