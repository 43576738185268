import { Component, Input, OnInit } from '@angular/core';
import { ILink } from 'src/app/types/ILink';
import * as router_data from '../../../../assets/data/router_data';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input() title: string = "";

  links: ILink[];

  constructor() {
    this.links = router_data.links;
  }

  ngOnInit(): void {
  }

}
