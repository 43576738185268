<div class="main-wrapper">
    <div class="title">
        <h3>{{ title.toUpperCase() }}</h3>
    </div>

    <nav class="links">
        <div *ngFor="let link of links">
            <a [ngClass]="{active: link.name == title}" routerLink="{{link.route}}">{{ link.name }}</a>
        </div>
    </nav>
</div>