// Fichier exportant différentes données relatives aux images du site (décorations, svg des technologies, etc).

//Liste des images de décorations du carousel d'un projet
export let decorations: string[] = [
    "bubbles.png",
    "confetti.png",
    "lines.png",
    "stars.png",
    "wave.png",
    "zigzag.png"
];