<!-- <p>landing-page works!</p> -->

<app-header title="Accueil"></app-header>

<div class="main-wrapper">
    <svg class="waves" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#ffbd59" fill-opacity="1"
            d="M0,256L26.7,224C53.3,192,107,128,160,85.3C213.3,43,267,21,320,26.7C373.3,32,427,64,480,106.7C533.3,149,587,203,640,234.7C693.3,267,747,277,800,245.3C853.3,213,907,139,960,122.7C1013.3,107,1067,149,1120,170.7C1173.3,192,1227,192,1280,192C1333.3,192,1387,192,1413,192L1440,192L1440,0L1413.3,0C1386.7,0,1333,0,1280,0C1226.7,0,1173,0,1120,0C1066.7,0,1013,0,960,0C906.7,0,853,0,800,0C746.7,0,693,0,640,0C586.7,0,533,0,480,0C426.7,0,373,0,320,0C266.7,0,213,0,160,0C106.7,0,53,0,27,0L0,0Z">
        </path>
    </svg>

    <div class="intro">

        <h2>Bienvenue sur mon portfolio !</h2>

        <p>Je suis Julie Bonhomme, étudiante en informatique. J'ai réalisé ce portfolio afin de répertorier et présenter
            mes
            différents projets, dans le cadre de ma recherche d'alternance.
            Voici les différentes pages que vous y trouverez :
        </p>

        <ul>
            <li>La page <a routerLink="/projects">Mes projets</a> liste les projets d'école et personnels que j'ai
                réalisés.
                En cliquant sur l'un d'entre eux, vous aurez accès à ses informations, telles que des images
                d'illustration,
                la liste des technologies utilisées et une description détaillées du projet.</li>
            <li>La page <a routerLink="/about">À propos</a> vous donnera accès à différentes informations sur mon
                parcours, notamment avec un lien vers mon Github et mon CV.</li>
        </ul>

        <h4>Bonne visite !</h4>

        <button class="btn-link" routerLink="/projects">Voir mes projets</button>
    </div>
</div>