import { Injectable } from '@angular/core';
import { IProject } from '../../types/IProject';
import * as project_data from '../../../assets/data/project_data';
import { IImage } from 'src/app/types/IImage';
import { ImageService } from '../image/image.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private imageService: ImageService) { }

  listProjects() {
    let projects: IProject[] = project_data.projects;

    for (let project of projects) {
      if (project.images.length == 0) {
        project.images.push(this.imageService.placeholderImage);
      }
    }

    return projects;
  }

  getProject(projectid: number) {
    //à refaire en fonction du backend
    //projet par défaut (temp)
    //const project: IProject = project_data.hyruleCastle;

    let project: IProject = project_data.projects.filter(p => p.id == projectid)[0];
    if (project.images.length == 0) {
      project.images.push(this.imageService.placeholderImage);
    }

    return project;
  }

  //Fonctions de tri des projets

  sortProjects(projects: IProject[], key: "startedAt" | "name", order: "asc" | "desc") {
    /** Fonction retournant la liste des projets triés selon la clé key et l'ordre order donnés. */

    let sortedProjects: IProject[]

    if (key == "startedAt") {
      sortedProjects = projects.sort((a, b) => this.sortByStartedAt(a, b, order));
    }
    else {
      sortedProjects = projects.sort((a, b) => this.sortByName(a, b, order));
    }

    return sortedProjects;
  }

  sortByStartedAt(a: IProject, b: IProject, order: "asc" | "desc") {
    if (order == "asc") {
      return a.startedAt.getTime() - b.startedAt.getTime();
    }
    else {
      return b.startedAt.getTime() - a.startedAt.getTime();
    }
  }

  sortByName(a: IProject, b: IProject, order: "asc" | "desc") {
    if (order == "asc") {
      return a.name.localeCompare(b.name);
    }
    else {
      return b.name.localeCompare(a.name);
    }
  }

}
