import { Component, OnInit } from '@angular/core';
import { IProject } from 'src/app/types/IProject';
import { ProjectService } from 'src/app/services/project/project.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

  //Liste des projets affichés
  projects: IProject[];

  //Clé pour trier les projets (par défaut, triés en fonction de la valeur startedAt)
  sortingKey: "startedAt" | "name" = "startedAt";
  sortingKeys: string[][] = [["Date", "startedAt"], ["Nom", "name"]];
  sortingOrder: "asc" | "desc" = "asc";
  sortingOrders: string[][] = [["Croissant", "asc"], ["Décroissant", "desc"]];

  constructor(private projectService: ProjectService) {
    this.projects = this.projectService.sortProjects(this.projectService.listProjects(), this.sortingKey, this.sortingOrder);
  }

  ngOnInit(): void {
    this.minimizeDesc(this.projects, 85);
  }

  minimizeDesc(projects: IProject[], max_len: number) {
    /** Fonction qui réduit les descriptions des projects reçus lorsqu'elles
     * sont trop longues pour les projects cards. */

    for (let project of projects) {
      if (project.description && project.description.length > max_len) {
        project.shortDesc = project.description.substring(0, max_len) + "...";
      }
      else {
        project.shortDesc = project.description;
      }
    }
  }

  changeSortingKey(key: string) {
    if (key == "startedAt" || key == "name") {
      this.sortingKey = key;
      this.projects = this.projectService.sortProjects(this.projectService.listProjects(), this.sortingKey, this.sortingOrder);
    }
    //Gestion erreur ?
  }

  changeSortingOrder(order: string) {
    if (order == "asc" || order == "desc") {
      this.sortingOrder = order;
      this.projects = this.projectService.sortProjects(this.projectService.listProjects(), this.sortingKey, this.sortingOrder);
    }
    //Gestion erreur ?
  }

}
