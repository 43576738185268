<div class="card" routerLink="/project/{{project.id}}">
    <h4>{{project.name}}</h4>

    <div class="image">
        <img src="../../../assets/images/project/{{project.images[0].fileName}}" (error)="handleImageError($event)">
        <div></div>
    </div>

    <p>{{project.shortDesc}}</p>

</div>