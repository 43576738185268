<app-header title="À propos"></app-header>

<div class="main-wrapper">

    <div class="about-top">
        <div class="photo">
            <img src="../../../assets/images/about/moi_small.JPG">
        </div>

        <div class="presentation">
            <!-- <div class="presentation-left">
                <img *ngIf="leftDecoration" src="../../../assets/images/decorations/{{leftDecoration}}">
            </div> -->
            <div class="presentation-center">
                <h3>Julie BONHOMME</h3>
                <p>Étudiante en informatique en Bachelor à l'ETNA, je suis actuellement à la recherche d'une alternance
                    dans
                    le développement web.<br>
                    Passionnée de programmation et de création, j'adore apprendre de nouvelles choses au travers de
                    projets
                    personnels
                    et professionnels.<br>
                    J'ai hâte de vous rencontrer !<br>
                    Vous trouverez ci-dessous mon CV, que vous pouvez visionner et/ou télécharger.
                </p>
            </div>
            <!-- <div class="presentation-right">
                <img *ngIf="rightDecoration" src=" ../../../assets/images/decorations/{{rightDecoration}}">
            </div> -->
        </div>
    </div>

    <div class="access-cv">
        <button><a href="../../../assets/files/BONHOMMEJulie_CV.pdf" target="_blank"><i
                    class="fa-solid fa-file-lines"></i>
                &nbsp;&nbsp;Voir
                mon CV</a></button>
        <button><a href="../../../assets/files/BONHOMMEJulie_CV.pdf" target="_blank" download><i
                    class="fa-solid fa-file-arrow-down"></i>&nbsp;&nbsp;Télécharger mon CV</a></button>
    </div>
</div>