import { Component, Input, OnInit } from '@angular/core';
import { IProject } from 'src/app/types/IProject';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.css']
})

export class ProjectCardComponent implements OnInit {

  @Input() project: any;

  constructor() {

  }

  ngOnInit(): void {
  }

  //à mettre dans un service
  handleImageError(event: Event) {
    (event.target as HTMLImageElement).src = '../../../assets/images/project/placeholder.png';
  }

}
