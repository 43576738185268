// Fichier exportant différentes données relatives au router de l'application (liens ILink, etc).

import { ILink } from "src/app/types/ILink";

const linkHome: ILink = {
    name: "Accueil",
    route: "/home"
}
const linkProjects: ILink = {
    name: "Mes projets",
    route: "/projects"
}
const linkAbout: ILink = {
    name: "À propos",
    route: "/about"
}

export let links: ILink[] = [linkHome, linkProjects, linkAbout];