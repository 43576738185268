import { Injectable } from '@angular/core';
import * as image_data from '../../../assets/data/image_data';
import { IImage } from 'src/app/types/IImage';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  placeholderImage: IImage;

  constructor() {
    this.placeholderImage = {
      fileName: "placeholder.png",
      title: "Image introuvable.",
      caption: "Ce fichier n'existe plus ou n'a pas encore été ajouté."
    };
  }

  listDecorations() {
    const decorations: string[] = image_data.decorations;

    return decorations;
  }

  getRandomDecoration(decorations: string[]): string {
    //Récupération d'un index aléatoire de la liste decorations
    const index: number = Math.floor(Math.random() * decorations.length);

    const decorationName: string = decorations[index];

    return decorationName;
  }

  handleImageError(event: Event, placeholder: string): void {
    (event.target as HTMLImageElement).src = placeholder;
  }
}
