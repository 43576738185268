<app-header [title]=headerTitle></app-header>

<div class=" main-wrapper">
    <div class="project">
        <div *ngIf="project.isWip" class="wip-band">
            CE PROJET EST ENCORE EN COURS.
        </div>

        <h3>{{ project.name }}</h3>
        <div class="images">
            <div class="carousel-left">
                <img *ngIf="leftDecoration" src="../../../assets/images/decorations/{{leftDecoration}}">
            </div>
            <div class="carousel-center">
                <ngb-carousel *ngIf="project.images">
                    <ng-template ngbSlide *ngFor="let image of project.images">
                        <div class="picsum-img-wrapper">
                            <img class="carousel-image" src="../../../assets/images/project/{{image.fileName}}"
                                (error)="handleImageErrorProject($event, '../../../assets/images/project/placeholder.png')"
                                alt="{{image.title}}" />
                        </div>
                        <div *ngIf="image.title || image.caption" class="carousel-caption"
                            (mouseenter)="captionMouseEnter($event)" (mouseleave)="captionMouseLeave($event)">
                            <h3>{{image.title}}</h3>
                            <p>{{image.caption}}</p>
                        </div>
                    </ng-template>
                </ngb-carousel>
            </div>
            <div class="carousel-right">
                <img *ngIf="rightDecoration" src=" ../../../assets/images/decorations/{{rightDecoration}}">
            </div>
        </div>

        <!-- Revoir gestion date (début - fin ?) -->
        <div class="dates">
            <!-- Le projet est terminé -->
            <p *ngIf="shortFinishedDate && shortStartedDate != shortFinishedDate">Projet réalisé de {{ shortStartedDate
                }} à {{ shortFinishedDate
                }}.</p>

            <!-- Le projet est encore en cours -->
            <div *ngIf="!shortFinishedDate">
                <p>Projet débuté en {{ shortStartedDate }}.</p>
            </div>

            <!-- Le projet a été terminé le même mois qu'il a débuté -->
            <div *ngIf="shortStartedDate == shortFinishedDate">
                <p>Projet réalisé en {{ shortFinishedDate }}.</p>
            </div>
        </div>

        <div class="technologies">
            <div *ngFor="let technology of project.technologies">
                <img src="../../../assets/images/technologies/{{technology.fileName}}" title="{{technology.name}}">
            </div>
        </div>

        <p class="description">{{ project.description }}</p>

        <a *ngIf="project.githubLink" class="github-link" href="{{project.githubLink}}" target="_blank"><i
                class="fa-brands fa-square-github"></i></a>
    </div>

    <button class="btn-link" routerLink="/projects">Retour à la liste des projets</button>
</div>